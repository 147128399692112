import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'

export default function NavBar() {
  const [url, setUrl] = useState(window.location.pathname)

  const history = useNavigate()

  useEffect(() => {
    setUrl(window.location.pathname)
  }, [history])

  return (
    <NavBarContainer>
      <Name>
        <Logo src="/img/logo.png" />
        Valentin Macé
      </Name>
      <NavBarContent>
        <NavLinkHome to='/' isactive={`${url === '/'}`}>
          Home
        </NavLinkHome>
        <NavLinkProjects to='/projects' isactive={`${url === '/projects'}`}>
          Projects
        </NavLinkProjects>
        <NavLinkAbout to='/about' isactive={`${url === '/about'}`}>
          About
        </NavLinkAbout>
        <NavLinkContact to='/contact' isactive={`${url === '/contact'}`}>
          Contact
        </NavLinkContact>
      </NavBarContent>
      <Name></Name>
    </NavBarContainer >
  )
}

const NavBarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 0;
  margin-top: 20px;
`

const Logo = styled.img`
  width: 80px;
  padding: 0 20px;
`

const Name = styled.h1`
  font-size: 2rem;
  font-weight: 900;
  color: white;
  padding: 0 20px;
  display: flex;
  align-items: center;
  width: 30%;
`

const NavBarContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px transparent;
  border-radius: 50em;
  background-image: linear-gradient(#0E1129, #0E1129), 
                    linear-gradient(to right, #a546e0b5, #3123fa96);
  background-origin: border-box;
  background-clip: content-box, border-box;
  padding: 3px 3px;
// 
`

const NavLinkHome = styled(Link)`
  font-size: 1.2rem;
  padding: 5px 30px;
  color: white;
  text-decoration: none;
  border-radius: 50em;
  cursor: pointer;
  &:hover {
    background-image: linear-gradient(to right, #7937ab4b, #6430a940);
  }
  ${props => props.isactive === "true" && `
    background-image: linear-gradient(to right, #7937ab4b, #6430a940);
  `}
`
const NavLinkProjects = styled(Link)`
  font-size: 1.2rem;
  padding: 5px 30px;
  color: white;
  text-decoration: none;
  border-radius: 50em;
  cursor: pointer;
  &:hover {
    background-image: linear-gradient(to right, #6430a947, #4f2aa740);
  }
  ${props => props.isactive === "true" && `
    background-image: linear-gradient(to right, #6430a947, #4f2aa740);
  `}
`

const NavLinkAbout = styled(Link)`
  font-size: 1.2rem;
  padding: 5px 30px;
  color: white;
  text-decoration: none;
  border-radius: 50em;
  cursor: pointer;
  &:hover {
    background-image: linear-gradient(to right, #4f2aa740, #3822a642);
  }
  ${props => props.isactive === "true" && `
    background-image: linear-gradient(to right, #4f2aa740, #3822a642);
  `}
`

const NavLinkContact = styled(Link)`
  font-size: 1.2rem;
  padding: 5px 30px;
  color: white;
  text-decoration: none;
  border-radius: 50em;
  cursor: pointer;
  &:hover {
    background-image: linear-gradient(to right, #3822a642, #241ba449);
  }
  ${props => props.isactive === "true" && `
    background-image: linear-gradient(to right, #3822a642, #241ba449);
  `}
`