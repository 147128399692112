import React from 'react'
import styled from 'styled-components'

export default function Footer() {
  return (
    <Container>Footer</Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color : white;
  background-color: #266aaf2f;
  position: relative;
  z-index: 1;
`
