import './App.css';
import styled from 'styled-components';
import NavBar from './components/NavBar';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Projects from './pages/Projects';
import Footer from './components/Footer';
import RandomBlob from './components/Blob';
import InContruction from './pages/InContruction';

function App() {
  return (
    <AppContainer>
      <BlobContainer>
        <RandomBlob />
        <RandomBlob />
        <RandomBlob />
        <RandomBlob />
        <RandomBlob />
        <RandomBlob />
      </BlobContainer>
      {process.env.REACT_APP_IS_CONTRUCTION ? <InContruction /> : (
        <>
          <NavBar />
          <ContentContainer>
            <Routes>
              <Route path='/' exact element={<Home />} />
              <Route path='/about' element={<About />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/projects' element={<Projects />} />
              <Route path='*' element={<Home />} />
            </Routes>
          </ContentContainer>
          <Footer />
        </>
      )}
    </AppContainer>
  );
}

const BlobContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

const AppContainer = styled.div`
  background-color: #0E1129;
  width: 100%;
  max-width: 100%;
  min-height: 100vh;
`;

const ContentContainer = styled.div`
  min-height: 100vh;
  position: relative;
  z-index: 1;
`;

export default App;
