import React from 'react'
import styled from 'styled-components'

// import Spline from '@splinetool/react-spline';

// import Keyboard from '../components/3dKeyboard';


export default function Home() {
  return (
    <Container>
      <TitleDiv>
        <DevTitle>Full-Stack<br />Developer</DevTitle>
      </TitleDiv>
      <AnimDiv>
        {/* <Keyboard scene="https://prod.spline.design/FxYMsi8ev5gud6Hn/scene.splinecode"
          style={{ width: '100%' }}
        /> */}
        {/* <Keyboard /> */}
      </AnimDiv>
    </Container>
  )
}

const TitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const AnimDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  @media (max-width: 1690px) {
    width: 50%;
  }
`

// const Keyboard = styled(Spline)`
//   width: 100%;


// `



const Container = styled.div`
  width: 100%;
  display: flex;
  color : white;
  height: 90vh;
  padding: 30px;
  justify-content: space-around;
`


const BigTitle = styled.h1`
  font-size: 5rem;
  font-weight: 900;
  margin: 0;
`

const DevTitle = styled.h2`
  font-size: 8rem;
  font-weight: 900;
  margin: 0;
  @media (max-width: 1690px) {
    font-size: 6rem;
  }
  @media (max-width: 1200px) {
    font-size: 5rem;
  }
`
