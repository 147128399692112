import React from 'react';
import styled from 'styled-components';
import Goo from 'gooey-react'

export default function RandomBlob() {
  const [coords, setCoords] = React.useState([]);
  const [timing, setTiming] = React.useState([]);
  const [code, setCode] = React.useState("");
  const [size, setSize] = React.useState([]);
  const [globalTiming, setGlobalTiming] = React.useState(Math.floor(Math.random() * 600) + 200);

  React.useEffect(() => {
    const width = window.innerWidth
    const height = window.innerHeight
    const randomString = Math.random().toString(36).substring(2, 7)
    setCode(randomString)

    var coords = []
    for (let i = 0; i < 11; i++) {
      // random coords between -500 and window width/height + 1000
      coords.push({x: Math.floor(Math.random() * (width + 500)) - 750, y: Math.floor(Math.random() * (height + 500)) - 750})
    }
    setCoords(coords)

    var timing = []
    for (let i = 0; i < 5; i++) {
      timing.push(Math.floor(Math.random() * 30) + 10)
    }
    setTiming(timing)

    var size = []
    for (let i = 0; i < 4; i++) {
      size.push(Math.floor(Math.random() * 80) + 30)
    }
    setSize(size)
  }, []);

  if (coords.length === 0) {
    return null
  }

  return (
    <BlobContainer coords={coords} code={code} globalTiming={globalTiming}>
      <Goo intensity='strong'>
        <svg
          width="500px"
          height="500px"
        >
          <g style={{ animation: `rotate_back ${timing[0]}s linear infinite` }}>
            <circle
              cx="50%"
              cy="50%"
              r={size[0]}
              fill="#7937ab"
              style={{
                animation: `blob_four ${timing[1]}s ease-in-out -3s infinite alternate`,
              }}
            />
            <circle
              cx="50%"
              cy="50%"
              r={size[1]}
              fill="#6430a9"
              style={{
                animation: `blob_three ${timing[2]}s ease-in-out -3s infinite alternate`,
              }}
            />
            <circle
              cx="50%"
              cy="50%"
              r={size[2]}
              fill="#4f2aa7"
              style={{
                animation: `blob_two ${timing[3]}s ease-in-out -3s infinite alternate`,
              }}
            />
            <circle
              cx="50%"
              cy="50%"
              r={size[3]}
              fill="#3822a6"
              style={{
                animation: `blob_one ${timing[4]}s ease-in-out -3s infinite alternate`,
              }}
            />
          </g>
        </svg>
      </Goo>
    </BlobContainer>
  );
}



const BlobContainer = styled.div`
  position: absolute;
  z-index: 0;
  ${props => {return `animation: move_${props.code} ${props.globalTiming}s ease infinite;`}}
  display: flex;
  justify-content: center;
  align-items: center;
  @keyframes ${props => props.code && `move_${props.code}`} {
    ${props => props.coords.map((coord, index) => {
      return ` ${index * 10}% {
        transform: translate(${coord.x}px, ${coord.y}px);
      }`
})}
}
`