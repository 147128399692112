import React from 'react'
import styled from 'styled-components'

export default function InContruction() {
  return (
    <Container>
      <Title>Page under construction</Title>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 30px;
`

const Title = styled.h1`
  font-size: 5rem;
  color: white;
  @media (max-width: 1690px) {
    font-size: 4rem;
  }
  @media (max-width: 800px) {
    font-size: 3rem;
  }
  @media (max-width: 500px) {
    font-size: 2rem;
  }
`
